/* Custom fonts */
@font-face {
    font-family: Merriweather;
    src: url(./assets/fonts/Merriweather-Regular.otf);
    font-weight: 400;
}

@font-face {
    font-family: Merriweather;
    src: url(./assets/fonts/Merriweather-Bold.otf);
    font-weight: 700;
}

@font-face {
    font-family: Merriweather;
    src: url(./assets/fonts/Merriweather-Light.otf);
    font-weight: 200;
}

@font-face {
  font-family: DancingScript;
  src: url(./assets/fonts/Dancing-Script.ttf);
  font-weight: 400;
}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
    scroll-padding-top: 75px;
}

body {
    font-weight: normal;
    overflow-x: hidden;
    position: relative;
    background-color: #121212 !important;
    color: #fff !important;
    font-family: 'Merriweather', sans-serif !important;
}

h1,h2,h3,h4,h5,h6 {
    margin: 0;
    padding:0;
    line-height: normal;
}
h1 {
  font-family: Merriweather;
  line-height: 48px!important;
  font-weight: 200;
  font-style: normal;
  font-size: 40px;
}
h4 {
  font-family: DancingScript;
  font-size:16px!important;
  padding: 0 0.8em;
}
.separator {
  margin-top:2rem;
}
.vc_separator .vc_sep_holder {
  height: 1px;
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-width: 10%;
}
#nostrastoria {
  background-color: #fff;
  color: #121212;
}
.section {
  padding: 8rem;
}
.section-title {
  margin-top: 2rem;
}
.caption {
  font-size: 0.85rem;
  margin:2rem 26rem 2rem;
}
nav.navbar {
    position:fixed;
    display:flex;
    justify-content:space-between;
    align-items:center;
    width: 100%;
    top: 0;
    z-index: 9999;
    transition: 0.32s ease-in-out;
    background: rgb(0,0,0);
    background: -moz-linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 92%, rgba(0,0,0,0) 100%);
    background: -webkit-linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 92%, rgba(0,0,0,0) 100%);
    background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 92%, rgba(0,0,0,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
}


nav.navbar.scrolled {
    padding:0px 0;
    background-color: #121212;
}

nav.navbar a.navbar-brand {
    width: 9%;
    padding: 7.5px 10px 7.5px 0;
}


nav.navbar .navbar-nav .nav-link.navbar-link {
    color: #fff !important;
    font-size: 0.75rem;
    font-weight: normal;
    letter-spacing: 0.8px;
    padding: 0 25px;
    opacity: 0.75;
}

nav.navbar .navbar-nav .nav-link.navbar-link:hover,
nav.navbar .navbar-nav .nav-link.navbar-link.active {
    opacity: 1;
}

.links {
  display: flex;
  align-items:flex-end;
  flex-grow: unset!important;
}

.navbar-brand {
  align-items: center;
}
/* Slider */


.slider {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100%;
  }
  
  .slider--content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
  }
  .slider-img {
    height: 3%;
    width:auto;
  }
  
  .slider--feature {
    text-align: center;
  }
  
  .feature--title {
    font-size: 2.5rem;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
  }
  
  .feature--text {
    font-size: 1rem;
    color: #fff;
    margin: 1rem 0;
  }
  
  .slider__btn-right,
  .slider__btn-left {
    background: transparent;
    border: none;
    outline: none;
    font-size: 4rem;
    color: #eee;
    padding: 0 1rem;
    cursor: pointer;
    transition: transform 0.1s ease-in-out;
  }
  
  .feature__btn {
    background: #fff;
    text-transform: uppercase;
    border: none;
    color: #444;
    border: 1px solid #444;
    outline: none;
    font-weight: 700;
    padding: 0.8rem 2rem;
    cursor: pointer;
  }
  
  .slider__btn-left:hover,
  .slider__btn-right:hover {
    transform: scale(0.95);
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .fadeIn {
    animation: fadeIn 0.5s;
  }

